.news-detail
	.article-header
		border-bottom: thin solid #E0E0E0
		@apply pb-10px mb-25px
	.article-content
		@apply text-base font-normal leading-24px text-justify text-text3
		a
			&[href]
				@apply text-blue-500
		strong
			@apply font-semibold
		* + *
			@apply mt-5
	.related-news
		.img
			flex: 0 0 calc( 150/450*100%)
			a
				+img-ratio(85/150)
		.title
			@apply font-bold text-lg leading-23px mb-5px
		.item
			&+.item
				@apply mt-5
		.content
			@apply ml-15px
		.infos
			.cat
				~ .date
					&::before
						content: "|"
						@apply mx-10px
	.share
		a
			width: r(20px)
			height: r(20px)
			svg
				@apply w-full h-full object-contain
