// Overlay 

#overlay
	position: fixed
	top: 0
	left: 0
	width: 100%
	height: 100%
	background-color: rgba(#000 ,.7 )
	@apply pointer-events-none opacity-0 transition-all z-30
	&.active
		@apply pointer-events-auto opacity-100

.desktop-show
	@apply hidden xl:block

.mobile-show
	@apply block xl:hidden

ul
	&.list-item-added
		padding-left: 19px
		@apply list-disc
		@media (min-width: 1280px)
			padding-left: r(19px)

.wp-pagination
	@apply mt-30px xl:mt-12
	ul
		@apply list-none flex items-center flex-wrap gap-10px p-0 justify-center
		li
			@apply flex items-center justify-center transition-all uppercase
			@apply font-bold text-xl text-text6
			a
				width: r(41px)
				height: r(37px)
				border-radius: r(5px)
				@apply flex items-center justify-center bg-f5f5f5
			&.active,&:hover
				a
					@apply bg-primary text-white
