footer
	@apply text-base md:text-sm
	.logo
		max-width: r(296px)
		height: r(40px)
		@apply w-full
		img
			@apply w-full h-full object-contain
	.social-icons
		a
			width: r(50px)
			flex: 0 0 r(50px)
			height: r(50px)
			border: thin solid #fff
			@apply rounded-full flex items-center justify-center transition-all
			&:hover
				@apply shadow-white shadow-lg border-transparent
		i
			@apply text-2xl text-white
		img
			max-width: r(25px)
			@apply w-full h-full object-contain
	.download-brochure
		a
			@apply flex items-end
			img
				flex: 0 0 r(50px)
				width: r(50px)
				height: auto
		span
			@apply underline ml-10px
	.infos
		@apply font-normal leading-18px
		.item
			&+.item
				@apply mt-10px
		span
			flex: 0 0 r(50px)
			@apply text-white/50
		a
			@apply ml-10px xl:ml-5
	ul
		li
			@apply w-fit
			& + li
				@apply my-10px
			&[class*='current'],&:hover
				a
					@apply underline
			a
				@apply font-normal text-sm
	.col-2
		@apply xl:pl-30px
	.col-3
		@apply xl:pl-10px
		.title
			@apply xl:whitespace-nowrap
	.col-4
		@apply xl:pl-5
		.des
			@apply font-normal leading-22px text-white
		.subscribe-form
			border: thin solid #FFFFFF
			border-radius: r(100px)
			@apply w-full bg-primary mt-5
			&.form-invalid
				@apply mb-30px
			form
				@apply flex items-center relative
			.form-group
				flex: 0 0 r(250px)
			input
				@apply border-none bg-transparent
				&:not([type='submit'])
					padding-top: r(9px)
					padding-bottom: r(9px)
					@apply pl-5 w-full
					@apply font-normal leading-22px text-white/50
					@apply placeholder:text-white/50
				&[type='submit']
					@apply flex-1
			.wpcf7-spinner
				width: r(30px)
				background: url(../img/animation/loading.svg) no-repeat center
				background-size: contain
				height: r(30px)
				border-radius: 0
				left: r(20px)
				top: calc( 100% + 10/1920*100rem )
				@apply absolute mx-0
				&::before
					@apply hidden
			.wpcf7-not-valid-tip
				@apply hidden
			.wpcf7-response-output
				margin: 0
				padding: 0
				border: 0
				left: 0
				padding: 0
				top: calc( 100% + 10/1920*100rem )
				@apply absolute w-full
	.copyright
		@apply text-white/50 leading-18px
		p
			@media (max-width: 1023.98px)
				@apply mb-10px
			&:nth-of-type(2)
				@apply text-f5f5f5
	.term-condition
		@apply leading-18px text-white/50
		ul
			@apply p-0 list-none flex items-center
		li
			& + li
				&::before
					content: '|'
					@apply mx-5px
