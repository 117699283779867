.arrow-button
	.swiper-button-prev
		left: -calc(60/1920*100*1rem)
		@media (min-width: 1440px)
			left: r(-75px)
		&::after
			content: "\f053"
	.swiper-button-next
		right: -calc(60/1920*100*1rem)
		@media (min-width: 1440px)
			right: r(-75px)
		&::after
			content: "\f054"
	.swiper-button-prev,.swiper-button-next
		margin: 0
		top: 50%
		transform: translateY(-50%)
		width: r(50px)
		height: r(50px)
		-webkit-text-stroke: 0
		@apply rounded-full transition-all
		@media (min-width: 1280px)
			width: r(60px)
			height: r(60px)
		&::after
			font-weight: 100
			font-size: r(25px)
			line-height: r(25px)
			@apply font-Awesome6 transition-all text-text9
			@media (min-width: 1280px)
				font-size: r(32px)
				line-height: r(32px)
		&:hover
			@apply bg-primary
			&::after
				color: #fff
	@media (max-width: 1279.98px)
		display: flex
		justify-content: center
		align-items: center
		position: relative
		.swiper-button-prev,.swiper-button-next
			margin-top: calc(10/1920*100*1rem)
			position: static
			transform: translateY(0)
		.swiper-button-next
			margin-left: calc(10/1920*100*1rem)
	&.no-responsive
		@media (max-width: 1280px)
			display: contents
		.swiper-button-prev,.swiper-button-next
			position: absolute !important
			margin: 0 !important
			top: 50%
			transform: translateY(-50%)
		.swiper-button-prev
			@media (max-width: 1599.98px)
				left: -calc(40/1920*100*1rem)
			@media (max-width: 1366px)
				left: 0
		.swiper-button-next
			@media (max-width: 1599.98px)
				right: -calc(40/1920*100*1rem)
			@media (max-width: 1366px)
				right: 0
		@media (max-width: 1280px)
			.swiper-button-prev
				left: calc(10/1920*100*1rem)
			.swiper-button-next
				right: calc(10/1920*100*1rem)
	&.close-arrow
		@apply flex
		.swiper-button-prev,.swiper-button-next
			@apply static translate-y-0
		.swiper-button-next
			@apply ml-10px xl:ml-5
.swiper
	.swiper-lazy-preloader
		width: r(45px)
		height: r(45px)
		@apply border-secondary border-t-transparent
