.news-list
	.bn-3
		@media (min-width: 1024px)
			&:nth-of-type(2)
				.content
					.title
						@apply flex-none
					.ctn
						@apply flex-1
						@apply line-clamp-6
