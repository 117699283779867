.introduce-4
	&.section
		@media (min-width: 1024px)
			padding-top: r(45px)
			padding-bottom: r(55px)
	.icon
		width: r(130px)
		a
			+img-ratio(1/1)
			@apply rounded-full
			span
				font-size: r(48px)
				@apply font-bold uppercase
				@apply flex items-center justify-center
