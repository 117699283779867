.button
	transition: .3s all ease-in-out
	user-select: none
	*
		@media (min-width: 576px)
			white-space: nowrap
	.btn
		@apply flex items-center justify-center w-fit relative transition-all duration-300
	.btn-1
		padding: 0 r(50px) 0 0
		border: thin solid
		@apply border-secondary
		@apply bg-secondary
		@apply font-normal text-xl text-white overflow-hidden rounded-full
		&::before
			content: ''
			box-shadow: inset 0px r(4px) r(4px) rgba(0, 0, 0, 0.25)
			@apply absolute w-full h-full top-0 left-0 z-5
		&::after
			content: "\2b"
			@apply font-Awesome6 text-xl leading-5 text-white font-light
			@apply transition-all duration-300
			@apply absolute top-1/2 -translate-y-1/2 right-5
		span
			padding: r(10px) r(30px)
			@apply inline-flex bg-primary rounded-full transition-all duration-300 relative z-2
		&:hover
			padding: 0 0 0 r(50px)
			@apply bg-black
			&::after
				right: calc( 100% - 35/1920*100rem)
				@apply delay-150
		&.btn-white
			@apply bg-white border-white
			&::after
				@apply text-primary
