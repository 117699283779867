#main-banner
	.swiper-slide
		@media (min-width: 1280px)
			height: calc( 100vh - var(--fixed-header-height) )
		&.playVideo
			.des,.logo
				@apply opacity-0 scale-75
			// .wrapper
			// 	&::after,&::before
			// 		height: 0
			.video
				@apply opacity-100
	.video
		@apply absolute top-0 left-0 w-full h-full z-5
		@apply opacity-0 transition-all duration-500 pointer-events-none
		video
			@apply w-full h-full object-cover
	.img
		@apply h-full
		&::before
			content: ''
			background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%)
			opacity: 0.75
			@apply pointer-events-none w-full h-full absolute
		img
			@apply w-full object-cover h-full
	.des
		font-weight: 100
		margin-top: r(20px)
		line-height: 1.3
		font-size: r(16px)
		@apply text-white
		@media (min-width: 576px)
			font-size: r(25px)
		@media (min-width: 1280px)
			font-size: r(46px)
			line-height: r(60px)
			margin-top: r(60px)
	// @media (min-width: 1024px)
	// 	.wrapper
	// 		max-width: r(982px)
	// 		@apply mx-auto
	// 		&::after,&::before
	// 			content: ""
	// 			width: r(1px)
	// 			height: 0
	// 			background: #FFFFFF
	// 			opacity: 0.5
	// 			@apply absolute left-1/2 -translate-x-1/2 z-10
	// 			@apply transition-all duration-500
	// 		&::before
	// 			top: calc( 95% + 100/1920*100rem)
	// 		&::after
	// 			bottom: calc( 95% + 100/1920*100rem)
	.des,.logo
		@apply opacity-0 transition-all duration-500 scale-75
	.logo
		height: 9vw
		width: 100%
		@media (min-width: 1280px)
			width: r(584px)
			height: r(201px)
		img
			@apply w-full h-full object-contain
	&.initial
		.des,.logo
			@apply opacity-100 scale-100
		.des
			@apply delay-150
		// .wrapper
		// 	&::after,&::before
		// 		height: r(100px)
		// 		@apply delay-300
