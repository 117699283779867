.introduce-1
	.img
		a
			+img-ratio(388/690)
		&.has-video
			@apply relative
			&::before
				content: "\f04b"
				@extend .absolute-center
				@apply font-Awesome6 text-5xl z-3 text-white pointer-events-none
			&::after
				content: ''
				@apply absolute w-full h-full top-0 left-0 bg-black/50 z-1 pointer-events-none
