header
	z-index: 150
	padding: r(10px) 0
	@apply shadow-lg
	@media (min-width: 1280px)
		padding: r(20px) 0
	.header-wrapper
		height: 45px
		@media (min-width: 1280px)
			height: auto
		nav
			>ul
				@apply flex items-center
				li
					a
						@apply font-normal text-2xl text-text3 transition-all
						.char
							overflow: hidden
							color: transparent
							&:before,
							&:after
								visibility: visible
								// color: #FFF
								transition: transform 0.5s cubic-bezier(0.9,0,.2,1)
								transition-delay: calc( 0.2s + ( 0.02s * ( var(--char-index)) ) )
								@apply text-text3

							&:before
								transition-delay: calc( 0.02s * ( var(--char-index)) )
								transform: translateX(110%)
								@apply text-primary

						&:hover
							.char
								&:before
									transition-delay: calc( 0.2s + ( 0.02s * ( var(--char-index)) ) )
									transform: translateX(0%)
								&:after
									transition-delay: calc( 0.02s * ( var(--char-index)) )
									transform: translateX(-110%)
					&[class*='current'],&:hover
						a
							@apply text-primary
							.char
								&:before
									transition-delay: calc( 0.2s + ( 0.02s * ( var(--char-index)) ) )
									transform: translateX(0%)
								&:after
									transition-delay: calc( 0.02s * ( var(--char-index)) )
									transform: translateX(-110%)

					& + li
						@apply ml-10
	.language
		@apply sm:pl-15px sm:ml-15px pl-5px ml-5px
		@media (min-width: 576px)
			&::before
				content: ""
				width: 1px
				height: r(30px)
				@apply absolute top-1/2 -translate-y-1/2 left-0
				@apply bg-d9d9d9
				@media (min-width: 1280px)
					width: r(1px)
		.wpml-ls
			all: unset
		ul
			@apply relative
			li
				&:not([class*='current'])
					@apply absolute top-0 left-1/2 -translate-x-1/2
					@apply opacity-0 invisible pointer-events-none transition-all
					a
						@apply p-5px
						&:hover
							@apply bg-primary text-white
				a
					@apply font-light text-base sm:text-2xl text-primary transition-all block relative
					span
						@apply block
				&[class*='current']
					a
						@apply flex items-center
						&::after
							content: "\f078"
							@apply font-Awesome6 font-light text-sm sm:text-base ml-5px
			&:hover
				li
					@apply opacity-100 visible pointer-events-auto
					@apply top-full
	.search
		@apply xl:ml-10
		i
			@apply text-base sm:text-2xl text-primary
	.col-right
		@apply ml-auto
	.col-left
		.logo
			width: auto
			height: 100%
			@media (min-width: 1280px)
				width: r(174px)
				height: r(60px)
			@media (max-width: 1279.98px)
				@apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2
			img
				@apply w-full h-full object-contain
				@media (max-width: 1279.98px)
					padding: r(5px) 0

	.nav-mobile
		padding: r(20px) r(15px) r(50px)
		@apply w-full fixed top-0 -left-full opacity-0 pointer-events-none h-screen transition-all duration-500 bg-white
		@apply flex flex-col
		&.active
			@apply left-0 opacity-100 pointer-events-auto
		.close-nav-mobile
			i
				@apply text-4xl
		.logo
			@apply text-center
			img
				width: auto
				height: 10vw
				@apply object-contain
				@media (min-width: 768px)
					height: 18vw
		#autoCloneHere-MainMenu
			@apply flex flex-col flex-1 overflow-auto
		nav
			li
				padding: r(10px) 0
				@apply w-fit
				&[class*='current'],&:hover
					a
						@apply text-primary
			a
				@apply text-3xl transition-all
	.burger
		i
			@apply text-3xl

.search-overlay
	top: var(--fixed-header-height)
	@apply w-full fixed z-[100] bg-primary transition-all duration-300 opacity-0 pointer-events-none invisible
	@apply opacity-0 -translate-y-1/2
	#autoCloneHere-Logo
		@apply absolute -left-10 top-1/2 -translate-y-1/2 h-full transition-all opacity-0
		.logo
			@apply h-full
		img
			padding: r(10px)
			@apply brightness-0 invert h-full w-auto object-contain
	&.active
		@apply opacity-100 visible pointer-events-auto translate-y-0
		#autoCloneHere-Logo
			@apply left-0 opacity-40 delay-300
	.searchbox
		input
			flex: 0 0 calc( 100% - 80/1920*100rem )
			width: calc( 100% - 80/1920*100rem )
			padding: r(20px) 0
			@apply bg-transparent transition-all
			@apply text-white
			@apply placeholder:text-white
		button
			width: r(80px)
			@apply transition-all
			&:hover
				@apply bg-secondary
				i
					@apply scale-[.85]
			i
				@apply text-3xl text-white transition-all

#fixed-tool
	bottom: 5vh
	@apply fixed right-5px xl:right-5 z-[50]
	@apply flex flex-col
	a
		border: thin solid #FFFFFF
		width: 40px
		height: 40px
		@apply bg-secondary rounded-full
		@apply flex items-center justify-center
		@apply transition-all
		@media (min-width: 1280px)
			width: r(60px)
			height: r(60px)
		&:hover
			@apply bg-primary border-transparent
		i
			@apply text-2xl font-light text-white
		& + a
			@apply mt-15px
		&.scrollToTop
			@apply opacity-0 translate-y-full pointer-events-none
			&.active
				@apply opacity-100 translate-y-0 pointer-events-auto

.home
	header
		@apply -translate-y-full
	&.initial
		header
			@apply transition-all duration-500 translate-y-0
