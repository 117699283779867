.home-3
	.download-wrapper
		padding: r(20px)
		@media (min-width: 1024px)
			padding: r(30px) r(50px)
	.sub-title
		border-bottom: thin solid #CCCCCC
		@apply pb-15px
	.icon
		i
			font-size: r(44px)
			@apply text-primary transition-all
	.item
		@apply transition-all
		@media (min-width: 1280px)
			padding: r(10px) 0
			&:hover
				padding: r(10px)
				@apply bg-primary
				i
					@apply text-white
				.content
					@apply ml-10px
				.title,.date
					@apply text-white
	.content
		text-shadow: 0 0 r(25px) rgba(0, 0, 0, 0.1)
		@apply ml-5
		.title
			text-shadow: 0 0 r(25px) rgba(0, 0, 0, 0.1)
			@apply mt-5px block
