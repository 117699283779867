@charset "UTF-8";
.copy-ratio-absolute {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  transition: 0.3s ease-in-out all;
}

body, html {
  font-size: 17px;
}

@media (min-width: 576px) {
  body, html {
    font-size: 16px;
  }
}
@media (min-width: 1024px) {
  body, html {
    font-size: 16px;
  }
}
@media (min-width: 1280px) {
  body, html {
    font-size: 1vw;
  }
}
main {
  max-width: 100vw;
  overflow-x: hidden;
}

::selection {
  color: #fff;
  @apply bg-primary;
}

[tabindex]:focus {
  outline: 0 !important;
}

hr {
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0;
}

p {
  margin-top: 0;
  margin-bottom: 0;
}

abbr[title], abbr[data-original-title] {
  text-decoration: underline;
  -webkit-text-decoration: underline dotted;
  text-decoration: underline dotted;
  cursor: help;
  border-bottom: 0;
  -webkit-text-decoration-skip-ink: none;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 0;
  font-style: normal;
  line-height: inherit;
}

ol, ul, dl {
  margin-top: 0;
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0;
  margin-left: 0;
}

blockquote {
  margin: 0;
}

b, strong {
  font-weight: bolder;
}

small {
  font-size: 80%;
}

sub, sup {
  position: relative;
  font-size: 75%;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  text-decoration: none;
  background-color: transparent;
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
}

a:not([href]):not([tabindex]) {
  text-decoration: none;
}

a:not([href]):not([tabindex]):hover, a:not([href]):not([tabindex]):focus {
  text-decoration: none;
}

a:not([href]):not([tabindex]):focus {
  outline: 0;
}

pre, code, kbd, samp {
  font-family: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  font-size: 1em;
}

pre {
  margin-top: 0;
  margin-bottom: 0;
  overflow: auto;
}

figure {
  margin: 0;
}

img {
  border-style: none;
}

svg {
  overflow: hidden;
  vertical-align: middle;
}

table {
  border-collapse: collapse;
}

caption {
  padding-top: 0;
  padding-bottom: 0;
  color: #6c757d;
  text-align: left;
  caption-side: bottom;
}

th {
  text-align: inherit;
}

label {
  display: inline-block;
  margin-bottom: 0;
}

button {
  border-radius: 0;
}

button:focus {
  outline: none;
  outline: calc(5/1920*100*1rem) auto -webkit-focus-ring-color;
}

input, button, select, optgroup, textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button, input {
  overflow: visible;
}

button, select {
  text-transform: none;
}

select {
  word-wrap: normal;
}

button, [type=button], [type=reset], [type=submit] {
  -webkit-appearance: button;
}

button:not(:disabled), [type=button]:not(:disabled), [type=reset]:not(:disabled), [type=submit]:not(:disabled) {
  cursor: pointer;
}

button::-moz-focus-inner, [type=button]::-moz-focus-inner, [type=reset]::-moz-focus-inner, [type=submit]::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

input[type=radio], input[type=checkbox] {
  box-sizing: border-box;
  padding: 0;
}

input[type=date], input[type=time], input[type=datetime-local], input[type=month] {
  -webkit-appearance: listbox;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: 0;
  font-size: 1.5rem;
  line-height: inherit;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: none;
}

[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

template {
  display: none;
}

input:-webkit-autofill, input:-webkit-autofill:focus, button:-webkit-autofill, button:-webkit-autofill:focus, a:-webkit-autofill, a:-webkit-autofill:focus, .button-prev:-webkit-autofill, .button-prev:-webkit-autofill:focus, .button-next:-webkit-autofill, .button-next:-webkit-autofill:focus, textarea:-webkit-autofill, textarea:-webkit-autofill:focus, select:-webkit-autofill, select:-webkit-autofill:focus {
  transition: background-color 600000s 0s, color 600000s 0s;
}

input:focus, button:focus, a:focus, .button-prev:focus, .button-next:focus, textarea:focus, select:focus {
  outline: none;
}

img.noImage {
  padding: 5% !important;
  object-fit: contain !important;
}

.zoom-img, .linhvuc-detail-2 .item, .bn.bn-2, .bn.bn-3, .bn.bn-1 {
  overflow: hidden;
}

.zoom-img:hover img, .linhvuc-detail-2 .item:hover img, .bn.bn-2:hover img, .bn.bn-3:hover img, .bn.bn-1:hover img {
  transform: scale(1.05);
}

.zoom-img img, .linhvuc-detail-2 .item img, .bn.bn-2 img, .bn.bn-3 img, .bn.bn-1 img {
  transition: 0.3s all ease-in-out;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
.button {
  transition: 0.3s all ease-in-out;
  user-select: none;
}

@media (min-width: 576px) {
  .button * {
    white-space: nowrap;
  }
}
.button .btn {
  @apply flex items-center justify-center w-fit relative transition-all duration-300;
}

.button .btn-1 {
  padding: 0 2.6041666667rem 0 0;
  border: thin solid;
  @apply border-secondary;
  @apply bg-secondary;
  @apply font-normal text-xl text-white overflow-hidden rounded-full;
}

.button .btn-1::before {
  content: "";
  box-shadow: inset 0px 0.2083333333rem 0.2083333333rem rgba(0, 0, 0, 0.25);
  @apply absolute w-full h-full top-0 left-0 z-5;
}

.button .btn-1::after {
  content: "+";
  @apply font-Awesome6 text-xl leading-5 text-white font-light;
  @apply transition-all duration-300;
  @apply absolute top-1/2 -translate-y-1/2 right-5;
}

.button .btn-1 span {
  padding: 0.5208333333rem 1.5625rem;
  @apply inline-flex bg-primary rounded-full transition-all duration-300 relative z-2;
}

.button .btn-1:hover {
  padding: 0 0 0 2.6041666667rem;
  @apply bg-black;
}

.button .btn-1:hover::after {
  right: calc( 100% - 35/1920*100rem);
  @apply delay-150;
}

.button .btn-1.btn-white {
  @apply bg-white border-white;
}

.button .btn-1.btn-white::after {
  @apply text-primary;
}

.section {
  padding: 35px 0;
}

@media (min-width: 768px) {
  .section {
    padding: 40px 0;
  }
}
@media (min-width: 1024px) {
  .section {
    padding: 45px 0;
  }
}
@media (min-width: 1280px) {
  .section {
    padding: 3.125rem 0;
  }
}
.arrow-button .swiper-button-prev {
  left: -calc(3.125rem);
}

@media (min-width: 1440px) {
  .arrow-button .swiper-button-prev {
    left: -3.90625rem;
  }
}
.arrow-button .swiper-button-prev::after {
  content: "";
}

.arrow-button .swiper-button-next {
  right: -calc(3.125rem);
}

@media (min-width: 1440px) {
  .arrow-button .swiper-button-next {
    right: -3.90625rem;
  }
}
.arrow-button .swiper-button-next::after {
  content: "";
}

.arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
  margin: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2.6041666667rem;
  height: 2.6041666667rem;
  -webkit-text-stroke: 0;
  @apply rounded-full transition-all;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    width: 3.125rem;
    height: 3.125rem;
  }
}
.arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
  font-weight: 100;
  font-size: 1.3020833333rem;
  line-height: 1.3020833333rem;
  @apply font-Awesome6 transition-all text-text9;
}

@media (min-width: 1280px) {
  .arrow-button .swiper-button-prev::after, .arrow-button .swiper-button-next::after {
    font-size: 1.6666666667rem;
    line-height: 1.6666666667rem;
  }
}
.arrow-button .swiper-button-prev:hover, .arrow-button .swiper-button-next:hover {
  @apply bg-primary;
}

.arrow-button .swiper-button-prev:hover::after, .arrow-button .swiper-button-next:hover::after {
  color: #fff;
}

@media (max-width: 1279.98px) {
  .arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
  }

  .arrow-button .swiper-button-prev, .arrow-button .swiper-button-next {
    margin-top: calc(10/1920*100*1rem);
    position: static;
    transform: translateY(0);
  }

  .arrow-button .swiper-button-next {
    margin-left: calc(10/1920*100*1rem);
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive {
    display: contents;
  }
}
.arrow-button.no-responsive .swiper-button-prev, .arrow-button.no-responsive .swiper-button-next {
  position: absolute !important;
  margin: 0 !important;
  top: 50%;
  transform: translateY(-50%);
}

@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: 0;
  }
}
@media (max-width: 1599.98px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: -calc(2.0833333333rem);
  }
}
@media (max-width: 1366px) {
  .arrow-button.no-responsive .swiper-button-next {
    right: 0;
  }
}
@media (max-width: 1280px) {
  .arrow-button.no-responsive .swiper-button-prev {
    left: calc(10/1920*100*1rem);
  }

  .arrow-button.no-responsive .swiper-button-next {
    right: calc(10/1920*100*1rem);
  }
}
.arrow-button.close-arrow {
  @apply flex;
}

.arrow-button.close-arrow .swiper-button-prev, .arrow-button.close-arrow .swiper-button-next {
  @apply static translate-y-0;
}

.arrow-button.close-arrow .swiper-button-next {
  @apply ml-10px xl:ml-5;
}

.swiper .swiper-lazy-preloader {
  width: 2.34375rem;
  height: 2.34375rem;
  @apply border-secondary border-t-transparent;
}

.block-title {
  @apply font-light uppercase text-center text-primary;
  @apply text-2xl;
}

@media (min-width: 576px) {
  .block-title {
    @apply text-3xl;
  }
}
@media (min-width: 768px) {
  .block-title {
    @apply text-36px leading-47px;
  }
}
#overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  @apply pointer-events-none opacity-0 transition-all z-30;
}

#overlay.active {
  @apply pointer-events-auto opacity-100;
}

.desktop-show {
  @apply hidden xl:block;
}

.mobile-show {
  @apply block xl:hidden;
}

ul.list-item-added {
  padding-left: 19px;
  @apply list-disc;
}

@media (min-width: 1280px) {
  ul.list-item-added {
    padding-left: 0.9895833333rem;
  }
}
.wp-pagination {
  @apply mt-30px xl:mt-12;
}

.wp-pagination ul {
  @apply list-none flex items-center flex-wrap gap-10px p-0 justify-center;
}

.wp-pagination ul li {
  @apply flex items-center justify-center transition-all uppercase;
  @apply font-bold text-xl text-text6;
}

.wp-pagination ul li a {
  width: 2.1354166667rem;
  height: 1.9270833333rem;
  border-radius: 0.2604166667rem;
  @apply flex items-center justify-center bg-f5f5f5;
}

.wp-pagination ul li.active a, .wp-pagination ul li:hover a {
  @apply bg-primary text-white;
}

.alignnone {
  margin: 5px 20px 20px 0;
}

.aligncenter,
div.aligncenter {
  display: block;
  margin: 5px auto 5px auto;
}

.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.alignright {
  float: right;
  margin: 5px 0 20px 20px;
}

a img.alignnone {
  margin: 5px 20px 20px 0;
}

a img.alignleft {
  float: left;
  margin: 5px 20px 20px 0;
}

a img.aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.wp-caption {
  background: #fff;
  border: 1px solid #f0f0f0;
  max-width: 96%;
  padding: 5px 3px 10px;
  text-align: center;
}

.wp-caption.alignnone {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignleft {
  margin: 5px 20px 20px 0;
}

.wp-caption.alignright {
  margin: 5px 0 20px 20px;
}

.wp-caption img {
  border: 0 none;
  height: auto;
  margin: 0;
  max-width: 98.5%;
  padding: 0;
  width: auto;
}

.wp-caption p.wp-caption-text {
  font-size: 11px;
  line-height: 17px;
  margin: 0;
  padding: 0 4px 5px;
}

.screen-reader-text {
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute !important;
  width: 1px;
  word-wrap: normal !important;
}

.screen-reader-text:focus {
  background-color: #eee;
  clip: auto !important;
  clip-path: none;
  color: #444;
  display: block;
  font-size: 1em;
  height: auto;
  left: 5px;
  line-height: normal;
  padding: 15px 23px 14px;
  text-decoration: none;
  top: 5px;
  width: auto;
  z-index: 100000;
}

.bn.bn-1 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.2962962963%;
}

.bn.bn-1 .img a img, .bn.bn-1 .img a iframe, .bn.bn-1 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.bn-1 .title {
  @apply transition-all;
  @apply line-clamp-2;
  @apply font-medium text-xl;
}

@media (min-width: 768px) {
  .bn.bn-1 .title {
    @apply text-36px leading-47px;
  }
}
.bn.bn-1 .ctn {
  @apply font-normal text-base leading-21px;
  @apply transition-all;
  @apply line-clamp-3;
}

.bn.bn-1 .infos {
  @apply flex items-center;
  @apply font-normal text-sm leading-18px text-text9;
}

.bn.bn-1 .infos .cat ~ .date::before {
  content: "|";
  @apply mx-10px;
}

.bn.bn-1 .content {
  @apply grid gap-15px md:gap-25px mt-5;
}

.bn.bn-1:hover .title {
  @apply text-primary;
}

.bn.bn-3 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.2962962963%;
}

.bn.bn-3 .img a img, .bn.bn-3 .img a iframe, .bn.bn-3 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.bn-3 .title {
  @apply transition-all;
  @apply line-clamp-2;
  @apply font-medium text-xl;
}

@media (min-width: 768px) {
  .bn.bn-3 .title {
    @apply text-36px leading-47px;
  }
}
.bn.bn-3 .ctn {
  @apply font-normal text-base leading-21px;
  @apply transition-all;
  @apply line-clamp-3;
}

.bn.bn-3 .infos {
  @apply flex items-center;
  @apply font-normal text-sm leading-18px text-text9;
}

.bn.bn-3 .infos .cat ~ .date::before {
  content: "|";
  @apply mx-10px;
}

.bn.bn-3 .content {
  @apply grid gap-15px md:gap-25px mt-5;
}

.bn.bn-3:hover .title {
  @apply text-primary;
}

.bn.bn-2 {
  @apply relative h-full;
}

.bn.bn-2 .img {
  @apply h-full;
}

.bn.bn-2 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 83.3333333333%;
  @apply h-full;
}

.bn.bn-2 .img a img, .bn.bn-2 .img a iframe, .bn.bn-2 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media (min-width: 576px) {
  .bn.bn-2 .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 56.2365591398%;
  }

  .bn.bn-2 .img a img, .bn.bn-2 .img a iframe, .bn.bn-2 .img a video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.bn.bn-2 .img a::before {
  content: "";
  background: linear-gradient(180deg, rgba(82, 7, 4, 0) 41.98%, #490805 93.54%);
  @apply absolute top-0 left-0 w-full h-full z-3;
}

.bn.bn-2 .content {
  @apply text-white absolute bottom-0 w-full z-5 pointer-events-none;
  @apply p-10px sm:p-5 xl:p-30px;
}

.bn.bn-2 .title {
  @apply font-semibold text-2xl leading-30px;
}

.bn.bn-2 .ctn {
  @apply font-normal text-base leading-21px mt-10px;
  @apply line-clamp-2;
}

.bn.bn-2 .infos {
  @apply flex items-center mt-10px;
  @apply font-normal text-sm leading-18px;
}

.bn.bn-2 .infos .cat ~ .date::before {
  content: "|";
  @apply mx-10px;
}

.bn.bn-3 {
  @apply flex h-full flex-col;
}

.bn.bn-3 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.2962962963%;
}

.bn.bn-3 .img a img, .bn.bn-3 .img a iframe, .bn.bn-3 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.bn.bn-3 .title {
  @apply transition-all;
  @apply line-clamp-2;
  @apply font-medium text-xl;
  @apply flex-1;
}

@media (min-width: 768px) {
  .bn.bn-3 .title {
    @apply text-2xl leading-32px;
  }
}
.bn.bn-3 .ctn {
  @apply font-normal text-base leading-21px;
  @apply transition-all;
  @apply line-clamp-4;
}

.bn.bn-3 .infos {
  @apply flex items-center;
  @apply font-normal text-sm leading-18px text-text9;
}

.bn.bn-3 .infos .cat ~ .date::before {
  content: "|";
  @apply mx-10px;
}

.bn.bn-3 .content {
  @apply grid gap-15px mt-10px sm:mt-5;
  @apply flex-1 flex flex-col;
}

.bn.bn-3:hover .title {
  @apply text-primary;
}

.custom-fancybox-popup.fancybox__container .fancybox__carousel .fancybox__slide .fancybox__content {
  @apply p-0 bg-transparent;
}

.custom-fancybox-popup.fancybox__container .fancybox__carousel .fancybox__slide .fancybox__content .carousel__button {
  width: 2.6041666667rem;
  height: 2.6041666667rem;
  border-radius: 0;
  border-top-right-radius: 10px;
  @apply top-0 right-15px outline-none bg-primary;
}

@media (min-width: 576px) {
  .custom-fancybox-popup.fancybox__container .fancybox__carousel .fancybox__slide .fancybox__content .carousel__button {
    border-top-right-radius: 1.0416666667rem;
  }
}
.custom-fancybox-popup.fancybox__container .fancybox__carousel .fancybox__slide .fancybox__content .carousel__button i {
  @apply font-normal text-36px;
}

.custom-fancybox-popup .wrapper {
  padding: 0 0 50px;
  border-radius: 10px;
  @apply bg-white;
}

@media (min-width: 576px) {
  .custom-fancybox-popup .wrapper {
    border-radius: 1.0416666667rem;
  }
}
@media (min-width: 1024px) {
  .custom-fancybox-popup .wrapper {
    padding: 2.34375rem 1.5625rem 3.125rem;
  }
}
.custom-fancybox-popup .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.2604340568%;
}

.custom-fancybox-popup .img a img, .custom-fancybox-popup .img a iframe, .custom-fancybox-popup .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.custom-fancybox-popup .img a img {
  border-radius: 10px;
}

@media (min-width: 576px) {
  .custom-fancybox-popup .img a img {
    border-radius: 1.0416666667rem 1.0416666667rem 0 0;
  }
}
@media (min-width: 1024px) {
  .custom-fancybox-popup .img a img {
    border-radius: 1.0416666667rem;
  }
}
.custom-fancybox-popup .title {
  @apply text-2xl sm:text-32px uppercase text-primary font-light;
}

@media (min-width: 1024px) {
  .custom-fancybox-popup .title {
    @apply pr-50px;
  }
}
.custom-fancybox-popup .ctn {
  @apply font-light text-lg leading-27px text-text3 overflow-auto mt-5px pr-5px;
}

@media (min-width: 1024px) {
  .custom-fancybox-popup .ctn {
    max-height: 11.25rem;
  }
}
.custom-fancybox-popup .ctn::-webkit-scrollbar {
  width: 0.2604166667rem;
}

.custom-fancybox-popup .content {
  @apply lg:pl-30px px-5 lg:p-0;
}

.custom-fancybox-popup .link {
  @apply flex items-center text-lg leading-24px text-primary;
}

.custom-fancybox-popup .link i {
  @apply text-xl ml-30px;
}

@media (min-width: 1280px) {
  .custom-fancybox-popup .col-left {
    @apply -mr-30px;
  }
}
@media (min-width: 1280px) {
  .custom-fancybox-popup .col-right {
    @apply pl-30px;
  }
}
@tailwind base;
@tailwind components;
@tailwind utilities;
@layer components {
  *,
*::before,
*::after {
    box-sizing: border-box;
  }

  *::-webkit-scrollbar-track {
    border-radius: 1.0416666667rem;
    @apply bg-d9d9d9;
  }

  *::-webkit-scrollbar {
    width: 0;
    height: 0.2604166667rem;
  }

  *::-webkit-scrollbar-thumb {
    border-radius: 1.0416666667rem;
    @apply bg-primary;
  }

  html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  }

  body {
    line-height: 1.5;
    font-weight: 400;
    @apply font-body text-text3;
  }

  img {
    display: inline;
  }

  .absolute-center, .introduce-1 .img.has-video::before {
    @apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute;
  }

  main {
    margin-top: var(--fixed-header-height);
  }
}
.congbothongtin .icon i {
  font-size: 2.2916666667rem;
  @apply text-primary transition-all;
}

.congbothongtin .item {
  @apply transition-all;
}

@media (min-width: 1280px) {
  .congbothongtin .item {
    padding: 1.0416666667rem;
  }

  .congbothongtin .item:hover {
    @apply bg-primary;
  }

  .congbothongtin .item:hover i {
    @apply text-white;
  }

  .congbothongtin .item:hover .content {
    @apply ml-10px;
  }

  .congbothongtin .item:hover .title, .congbothongtin .item:hover .date {
    @apply text-white;
  }
}
.congbothongtin .content {
  text-shadow: 0 0 1.3020833333rem rgba(0, 0, 0, 0.1);
  @apply ml-5;
}

.congbothongtin .content .title {
  text-shadow: 0 0 1.3020833333rem rgba(0, 0, 0, 0.1);
}

.contact .des {
  @apply font-normal text-lg leading-23px text-text3;
}

.contact .form-group textarea, .contact .form-group input:not([type=submit]) {
  padding: 0.703125rem 0.7291666667rem;
  border: thin solid #f0f0f0;
  @apply font-normal text-lg leading-23px;
  @apply w-full;
}

.contact .form-group textarea {
  height: 7.8125rem;
  @apply resize-none;
}

.contact .submit-form {
  @apply grid gap-10px;
}

@media (max-width: 575.98px) {
  .contact .submit-form {
    @apply mt-10px;
  }
}
.contact .submit-form input {
  width: 10.9375rem;
  height: 2.3958333333rem;
  @apply bg-primary rounded-full;
  @apply font-normal text-xl leading-5 text-white;
}

.contact .form-submit {
  @apply flex justify-between;
}

@media (max-width: 575.98px) {
  .contact .form-submit {
    @apply flex-col;
  }
}
.contact .contact-form form {
  @apply grid gap-5;
}

.contact .iframe {
  height: 21.9270833333rem;
  @apply w-full;
}

.contact .iframe iframe {
  @apply w-full h-full;
}

.contact .title {
  font-size: 1.0416666667rem;
  @apply font-semibold text-primary;
}

@media (min-width: 576px) {
  .contact .title {
    font-size: 1.3541666667rem;
    line-height: 1.6145833333rem;
  }
}
.contact .infos {
  @apply font-normal text-base sm:text-lg leading-30px mt-15px mb-5;
}

.contact .infos .icon {
  flex: 0 0 1.0416666667rem;
}

.contact .infos .icon i {
  @apply text-lg leading-30px text-text3;
}

.contact .infos .ctn {
  @apply ml-10px sm:ml-5;
}

.search-page {
  background-color: #f0f0f2;
}

.search-page .search-form form {
  @apply relative;
}

.search-page .search-form form input {
  padding: 0.5208333333rem 1.0416666667rem;
  border: thin solid;
  padding-right: 2.34375rem;
  @apply w-full bg-transparent transition-all border-primary rounded-lg;
}

.search-page .search-form form input:not(:placeholder-shown) {
  @apply bg-primary/[.15] border-transparent;
}

.search-page .search-form form button {
  width: 2.0833333333rem;
  @apply h-full absolute top-0 right-0 flex items-center justify-center;
}

.search-page .search-query {
  @apply my-15px font-bold;
}

.search-page .found-nothing {
  background-image: url(../img/nothing.png);
  height: 30vh;
  @apply bg-no-repeat bg-center;
}

.search-page .found-nothing-title {
  @apply text-xl uppercase relative font-bold text-primary;
}

footer {
  @apply text-base md:text-sm;
}

footer .logo {
  max-width: 15.4166666667rem;
  height: 2.0833333333rem;
  @apply w-full;
}

footer .logo img {
  @apply w-full h-full object-contain;
}

footer .social-icons a {
  width: 2.6041666667rem;
  flex: 0 0 2.6041666667rem;
  height: 2.6041666667rem;
  border: thin solid #fff;
  @apply rounded-full flex items-center justify-center transition-all;
}

footer .social-icons a:hover {
  @apply shadow-white shadow-lg border-transparent;
}

footer .social-icons i {
  @apply text-2xl text-white;
}

footer .social-icons img {
  max-width: 1.3020833333rem;
  @apply w-full h-full object-contain;
}

footer .download-brochure a {
  @apply flex items-end;
}

footer .download-brochure a img {
  flex: 0 0 2.6041666667rem;
  width: 2.6041666667rem;
  height: auto;
}

footer .download-brochure span {
  @apply underline ml-10px;
}

footer .infos {
  @apply font-normal leading-18px;
}

footer .infos .item + .item {
  @apply mt-10px;
}

footer .infos span {
  flex: 0 0 2.6041666667rem;
  @apply text-white/50;
}

footer .infos a {
  @apply ml-10px xl:ml-5;
}

footer ul li {
  @apply w-fit;
}

footer ul li + li {
  @apply my-10px;
}

footer ul li[class*=current] a, footer ul li:hover a {
  @apply underline;
}

footer ul li a {
  @apply font-normal text-sm;
}

footer .col-2 {
  @apply xl:pl-30px;
}

footer .col-3 {
  @apply xl:pl-10px;
}

footer .col-3 .title {
  @apply xl:whitespace-nowrap;
}

footer .col-4 {
  @apply xl:pl-5;
}

footer .col-4 .des {
  @apply font-normal leading-22px text-white;
}

footer .col-4 .subscribe-form {
  border: thin solid #FFFFFF;
  border-radius: 5.2083333333rem;
  @apply w-full bg-primary mt-5;
}

footer .col-4 .subscribe-form.form-invalid {
  @apply mb-30px;
}

footer .col-4 .subscribe-form form {
  @apply flex items-center relative;
}

footer .col-4 .subscribe-form .form-group {
  flex: 0 0 13.0208333333rem;
}

footer .col-4 .subscribe-form input {
  @apply border-none bg-transparent;
}

footer .col-4 .subscribe-form input:not([type=submit]) {
  padding-top: 0.46875rem;
  padding-bottom: 0.46875rem;
  @apply pl-5 w-full;
  @apply font-normal leading-22px text-white/50;
  @apply placeholder:text-white/50;
}

footer .col-4 .subscribe-form input[type=submit] {
  @apply flex-1;
}

footer .col-4 .subscribe-form .wpcf7-spinner {
  width: 1.5625rem;
  background: url(../img/animation/loading.svg) no-repeat center;
  background-size: contain;
  height: 1.5625rem;
  border-radius: 0;
  left: 1.0416666667rem;
  top: calc( 100% + 10/1920*100rem );
  @apply absolute mx-0;
}

footer .col-4 .subscribe-form .wpcf7-spinner::before {
  @apply hidden;
}

footer .col-4 .subscribe-form .wpcf7-not-valid-tip {
  @apply hidden;
}

footer .col-4 .subscribe-form .wpcf7-response-output {
  margin: 0;
  padding: 0;
  border: 0;
  left: 0;
  padding: 0;
  top: calc( 100% + 10/1920*100rem );
  @apply absolute w-full;
}

footer .copyright {
  @apply text-white/50 leading-18px;
}

@media (max-width: 1023.98px) {
  footer .copyright p {
    @apply mb-10px;
  }
}
footer .copyright p:nth-of-type(2) {
  @apply text-f5f5f5;
}

footer .term-condition {
  @apply leading-18px text-white/50;
}

footer .term-condition ul {
  @apply p-0 list-none flex items-center;
}

footer .term-condition li + li::before {
  content: "|";
  @apply mx-5px;
}

header {
  z-index: 150;
  padding: 0.5208333333rem 0;
  @apply shadow-lg;
}

@media (min-width: 1280px) {
  header {
    padding: 1.0416666667rem 0;
  }
}
header .header-wrapper {
  height: 45px;
}

@media (min-width: 1280px) {
  header .header-wrapper {
    height: auto;
  }
}
header .header-wrapper nav > ul {
  @apply flex items-center;
}

header .header-wrapper nav > ul li a {
  @apply font-normal text-2xl text-text3 transition-all;
}

header .header-wrapper nav > ul li a .char {
  overflow: hidden;
  color: transparent;
}

header .header-wrapper nav > ul li a .char:before, header .header-wrapper nav > ul li a .char:after {
  visibility: visible;
  transition: transform 0.5s cubic-bezier(0.9, 0, 0.2, 1);
  transition-delay: calc( 0.2s + ( 0.02s * ( var(--char-index)) ) );
  @apply text-text3;
}

header .header-wrapper nav > ul li a .char:before {
  transition-delay: calc( 0.02s * ( var(--char-index)) );
  transform: translateX(110%);
  @apply text-primary;
}

header .header-wrapper nav > ul li a:hover .char:before {
  transition-delay: calc( 0.2s + ( 0.02s * ( var(--char-index)) ) );
  transform: translateX(0%);
}

header .header-wrapper nav > ul li a:hover .char:after {
  transition-delay: calc( 0.02s * ( var(--char-index)) );
  transform: translateX(-110%);
}

header .header-wrapper nav > ul li[class*=current] a, header .header-wrapper nav > ul li:hover a {
  @apply text-primary;
}

header .header-wrapper nav > ul li[class*=current] a .char:before, header .header-wrapper nav > ul li:hover a .char:before {
  transition-delay: calc( 0.2s + ( 0.02s * ( var(--char-index)) ) );
  transform: translateX(0%);
}

header .header-wrapper nav > ul li[class*=current] a .char:after, header .header-wrapper nav > ul li:hover a .char:after {
  transition-delay: calc( 0.02s * ( var(--char-index)) );
  transform: translateX(-110%);
}

header .header-wrapper nav > ul li + li {
  @apply ml-10;
}

header .language {
  @apply sm:pl-15px sm:ml-15px pl-5px ml-5px;
}

@media (min-width: 576px) {
  header .language::before {
    content: "";
    width: 1px;
    height: 1.5625rem;
    @apply absolute top-1/2 -translate-y-1/2 left-0;
    @apply bg-d9d9d9;
  }
}
@media (min-width: 576px) and (min-width: 1280px) {
  header .language::before {
    width: 0.0520833333rem;
  }
}
header .language .wpml-ls {
  all: unset;
}

header .language ul {
  @apply relative;
}

header .language ul li:not([class*=current]) {
  @apply absolute top-0 left-1/2 -translate-x-1/2;
  @apply opacity-0 invisible pointer-events-none transition-all;
}

header .language ul li:not([class*=current]) a {
  @apply p-5px;
}

header .language ul li:not([class*=current]) a:hover {
  @apply bg-primary text-white;
}

header .language ul li a {
  @apply font-light text-base sm:text-2xl text-primary transition-all block relative;
}

header .language ul li a span {
  @apply block;
}

header .language ul li[class*=current] a {
  @apply flex items-center;
}

header .language ul li[class*=current] a::after {
  content: "";
  @apply font-Awesome6 font-light text-sm sm:text-base ml-5px;
}

header .language ul:hover li {
  @apply opacity-100 visible pointer-events-auto;
  @apply top-full;
}

header .search {
  @apply xl:ml-10;
}

header .search i {
  @apply text-base sm:text-2xl text-primary;
}

header .col-right {
  @apply ml-auto;
}

header .col-left .logo {
  width: auto;
  height: 100%;
}

@media (min-width: 1280px) {
  header .col-left .logo {
    width: 9.0625rem;
    height: 3.125rem;
  }
}
@media (max-width: 1279.98px) {
  header .col-left .logo {
    @apply absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2;
  }
}
header .col-left .logo img {
  @apply w-full h-full object-contain;
}

@media (max-width: 1279.98px) {
  header .col-left .logo img {
    padding: 0.2604166667rem 0;
  }
}
header .nav-mobile {
  padding: 1.0416666667rem 0.78125rem 2.6041666667rem;
  @apply w-full fixed top-0 -left-full opacity-0 pointer-events-none h-screen transition-all duration-500 bg-white;
  @apply flex flex-col;
}

header .nav-mobile.active {
  @apply left-0 opacity-100 pointer-events-auto;
}

header .nav-mobile .close-nav-mobile i {
  @apply text-4xl;
}

header .nav-mobile .logo {
  @apply text-center;
}

header .nav-mobile .logo img {
  width: auto;
  height: 10vw;
  @apply object-contain;
}

@media (min-width: 768px) {
  header .nav-mobile .logo img {
    height: 18vw;
  }
}
header .nav-mobile #autoCloneHere-MainMenu {
  @apply flex flex-col flex-1 overflow-auto;
}

header .nav-mobile nav li {
  padding: 0.5208333333rem 0;
  @apply w-fit;
}

header .nav-mobile nav li[class*=current] a, header .nav-mobile nav li:hover a {
  @apply text-primary;
}

header .nav-mobile nav a {
  @apply text-3xl transition-all;
}

header .burger i {
  @apply text-3xl;
}

.search-overlay {
  top: var(--fixed-header-height);
  @apply w-full fixed z-[100] bg-primary transition-all duration-300 opacity-0 pointer-events-none invisible;
  @apply opacity-0 -translate-y-1/2;
}

.search-overlay #autoCloneHere-Logo {
  @apply absolute -left-10 top-1/2 -translate-y-1/2 h-full transition-all opacity-0;
}

.search-overlay #autoCloneHere-Logo .logo {
  @apply h-full;
}

.search-overlay #autoCloneHere-Logo img {
  padding: 0.5208333333rem;
  @apply brightness-0 invert h-full w-auto object-contain;
}

.search-overlay.active {
  @apply opacity-100 visible pointer-events-auto translate-y-0;
}

.search-overlay.active #autoCloneHere-Logo {
  @apply left-0 opacity-40 delay-300;
}

.search-overlay .searchbox input {
  flex: 0 0 calc( 100% - 80/1920*100rem );
  width: calc( 100% - 80/1920*100rem );
  padding: 1.0416666667rem 0;
  @apply bg-transparent transition-all;
  @apply text-white;
  @apply placeholder:text-white;
}

.search-overlay .searchbox button {
  width: 4.1666666667rem;
  @apply transition-all;
}

.search-overlay .searchbox button:hover {
  @apply bg-secondary;
}

.search-overlay .searchbox button:hover i {
  @apply scale-[.85];
}

.search-overlay .searchbox button i {
  @apply text-3xl text-white transition-all;
}

#fixed-tool {
  bottom: 5vh;
  @apply fixed right-5px xl:right-5 z-[50];
  @apply flex flex-col;
}

#fixed-tool a {
  border: thin solid #FFFFFF;
  width: 40px;
  height: 40px;
  @apply bg-secondary rounded-full;
  @apply flex items-center justify-center;
  @apply transition-all;
}

@media (min-width: 1280px) {
  #fixed-tool a {
    width: 3.125rem;
    height: 3.125rem;
  }
}
#fixed-tool a:hover {
  @apply bg-primary border-transparent;
}

#fixed-tool a i {
  @apply text-2xl font-light text-white;
}

#fixed-tool a + a {
  @apply mt-15px;
}

#fixed-tool a.scrollToTop {
  @apply opacity-0 translate-y-full pointer-events-none;
}

#fixed-tool a.scrollToTop.active {
  @apply opacity-100 translate-y-0 pointer-events-auto;
}

.home header {
  @apply -translate-y-full;
}

.home.initial header {
  @apply transition-all duration-500 translate-y-0;
}

.global-breadcrumb {
  border-bottom: thin solid #EDEDED;
  @apply bg-white;
}

.global-breadcrumb nav.rank-math-breadcrumb {
  padding: 0.703125rem 0;
  @apply text-base font-normal leading-18px text-text6;
}

.global-breadcrumb nav.rank-math-breadcrumb span.separator {
  margin: 0 5px;
  font-size: 0;
}

@media (min-width: 768px) {
  .global-breadcrumb nav.rank-math-breadcrumb span.separator {
    margin: 0 1.5625rem;
  }
}
.global-breadcrumb nav.rank-math-breadcrumb span.separator::before {
  content: "|";
  @apply text-base;
}

.global-breadcrumb a:hover {
  @apply text-primary;
}

.single .global-breadcrumb a:nth-of-type(3) {
  @apply hidden;
}

.single .global-breadcrumb span:nth-of-type(2), .single .global-breadcrumb span:nth-of-type(3), .single .global-breadcrumb span:nth-of-type(4) {
  @apply hidden;
}

@media (min-width: 1280px) {
  #main-banner .swiper-slide {
    height: calc( 100vh - var(--fixed-header-height) );
  }
}
#main-banner .swiper-slide.playVideo .des, #main-banner .swiper-slide.playVideo .logo {
  @apply opacity-0 scale-75;
}

#main-banner .swiper-slide.playVideo .video {
  @apply opacity-100;
}

#main-banner .video {
  @apply absolute top-0 left-0 w-full h-full z-5;
  @apply opacity-0 transition-all duration-500 pointer-events-none;
}

#main-banner .video video {
  @apply w-full h-full object-cover;
}

#main-banner .img {
  @apply h-full;
}

#main-banner .img::before {
  content: "";
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #000000 100%);
  opacity: 0.75;
  @apply pointer-events-none w-full h-full absolute;
}

#main-banner .img img {
  @apply w-full object-cover h-full;
}

#main-banner .des {
  font-weight: 100;
  margin-top: 1.0416666667rem;
  line-height: 1.3;
  font-size: 0.8333333333rem;
  @apply text-white;
}

@media (min-width: 576px) {
  #main-banner .des {
    font-size: 1.3020833333rem;
  }
}
@media (min-width: 1280px) {
  #main-banner .des {
    font-size: 2.3958333333rem;
    line-height: 3.125rem;
    margin-top: 3.125rem;
  }
}
#main-banner .des, #main-banner .logo {
  @apply opacity-0 transition-all duration-500 scale-75;
}

#main-banner .logo {
  height: 9vw;
  width: 100%;
}

@media (min-width: 1280px) {
  #main-banner .logo {
    width: 30.4166666667rem;
    height: 10.46875rem;
  }
}
#main-banner .logo img {
  @apply w-full h-full object-contain;
}

#main-banner.initial .des, #main-banner.initial .logo {
  @apply opacity-100 scale-100;
}

#main-banner.initial .des {
  @apply delay-150;
}

.home-1 {
  @apply md:bg-fixed;
}

.home-1 .des {
  max-width: 51.1979166667rem;
}

@media (max-width: 1023.98px) {
  .home-1 .des {
    filter: drop-shadow(2px 4px 6px black);
  }
}
.home-2 .des {
  max-width: 60.78125rem;
}

.home-2 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 85%;
}

.home-2 .img a img, .home-2 .img a iframe, .home-2 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

@media (min-width: 576px) {
  .home-2 .img a {
    position: relative;
    display: block;
    height: 0;
    overflow: hidden;
    padding-top: 184.6153846154%;
  }

  .home-2 .img a img, .home-2 .img a iframe, .home-2 .img a video {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    object-fit: cover;
    transition: 0.3s ease-in-out all;
  }
}
.home-2 .img a::before {
  content: "";
  background: rgba(0, 0, 0, 0.3);
  @apply w-full left-0 top-0 h-full z-2 absolute transition-all duration-300;
}

.home-2 .flow-title {
  writing-mode: vertical-lr;
  white-space: nowrap;
  transform: scale(-1);
  bottom: 10px;
  left: 10px;
  @apply text-36px font-bold;
}

@media (min-width: 1280px) {
  .home-2 .flow-title {
    bottom: 3.125rem;
    left: 2.6041666667rem;
  }
}
.home-2 .title, .home-2 .ctn {
  @apply transition-all duration-300;
}

.home-2 .content {
  bottom: 0;
  padding: 10px;
  @apply font-normal text-base leading-21px overflow-hidden;
}

@media (min-width: 1280px) {
  .home-2 .content {
    padding: 2.6041666667rem 3.125rem 2.8645833333rem;
  }
}
.home-2 .content .title, .home-2 .content .ctn {
  @apply opacity-0 translate-x-10;
}

.home-2 .title {
  @apply text-36px;
}

@media (min-width: 576px) {
  .home-2 .item:hover .flow-title {
    @apply -translate-x-full -scale-100 opacity-0;
  }

  .home-2 .item:hover .content .title, .home-2 .item:hover .content .ctn {
    @apply translate-x-0 opacity-100 delay-150 duration-500;
  }

  .home-2 .item:hover .content .ctn {
    @apply delay-300;
  }

  .home-2 .item:hover .img a::before {
    background: rgba(0, 0, 0, 0.5);
  }
}
@media (max-width: 575.98px) {
  .home-2 .item .flow-title {
    @apply -translate-x-full -scale-100 opacity-0;
  }

  .home-2 .item .content .title, .home-2 .item .content .ctn {
    @apply translate-x-0 opacity-100 delay-150 duration-500;
  }

  .home-2 .item .content .ctn {
    @apply delay-300;
  }

  .home-2 .item .img a::before {
    background: rgba(0, 0, 0, 0.5);
  }
}
.home-3 .download-wrapper {
  padding: 1.0416666667rem;
}

@media (min-width: 1024px) {
  .home-3 .download-wrapper {
    padding: 1.5625rem 2.6041666667rem;
  }
}
.home-3 .sub-title {
  border-bottom: thin solid #CCCCCC;
  @apply pb-15px;
}

.home-3 .icon i {
  font-size: 2.2916666667rem;
  @apply text-primary transition-all;
}

.home-3 .item {
  @apply transition-all;
}

@media (min-width: 1280px) {
  .home-3 .item {
    padding: 0.5208333333rem 0;
  }

  .home-3 .item:hover {
    padding: 0.5208333333rem;
    @apply bg-primary;
  }

  .home-3 .item:hover i {
    @apply text-white;
  }

  .home-3 .item:hover .content {
    @apply ml-10px;
  }

  .home-3 .item:hover .title, .home-3 .item:hover .date {
    @apply text-white;
  }
}
.home-3 .content {
  text-shadow: 0 0 1.3020833333rem rgba(0, 0, 0, 0.1);
  @apply ml-5;
}

.home-3 .content .title {
  text-shadow: 0 0 1.3020833333rem rgba(0, 0, 0, 0.1);
  @apply mt-5px block;
}

.home-4 .swiper-slide:nth-of-type(even) {
  @apply mt-10;
}

nav.common-nav ul {
  @apply list-none p-0;
  @apply overflow-auto;
}

nav.common-nav li:hover, nav.common-nav li.active {
  @apply text-primary;
}

nav.common-nav li a {
  @apply whitespace-nowrap;
}

.home-5 .img {
  @apply w-full;
}

.home-5 .img img {
  height: 5.2083333333rem;
  @apply w-auto object-contain transition-all duration-300;
}

.home-5 .img:hover img {
  @apply scale-90;
}

.introduce-1 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.231884058%;
}

.introduce-1 .img a img, .introduce-1 .img a iframe, .introduce-1 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.introduce-1 .img.has-video {
  @apply relative;
}

.introduce-1 .img.has-video::before {
  content: "";
  @apply font-Awesome6 text-5xl z-3 text-white pointer-events-none;
}

.introduce-1 .img.has-video::after {
  content: "";
  @apply absolute w-full h-full top-0 left-0 bg-black/50 z-1 pointer-events-none;
}

@media (min-width: 1280px) {
  .introduce-2 {
    padding-top: 2.6041666667rem;
  }
}
.introduce-2 .item {
  @apply h-full flex flex-col;
}

.introduce-2 .item .content {
  @apply font-light text-base leading-21px text-text3;
  @apply flex-1;
}

.introduce-2 .item .line {
  width: 0.1041666667rem;
  left: 0.2604166667rem;
  @apply h-full bg-primary absolute;
}

.introduce-2 .item .line::before, .introduce-2 .item .line::after {
  content: "";
  width: 0.5208333333rem;
  height: 0.5208333333rem;
  @apply absolute bg-primary rounded-full -translate-x-1/2 left-1/2;
}

.introduce-2 .item .line::before {
  @apply top-0;
}

.introduce-2 .item .line::after {
  @apply bottom-0;
}

.introduce-2 .item .ctn {
  max-height: 7.65625rem;
  @apply pl-5 overflow-auto;
}

.introduce-2 .item .ctn::-webkit-scrollbar {
  width: 0.15625rem;
  height: 0.15625rem;
}

.introduce-2 .item .year {
  clip-path: polygon(0% 0%, 94% 0%, 100% 50%, 94% 100%, 0% 100%);
  padding: 0.2083333333rem 1.3020833333rem;
  @apply bg-primary;
  @apply font-semibold text-2xl text-white mt-35px;
}

.introduce-2 .swiper-slide {
  @apply h-auto;
}

.introduce-3 .img {
  @apply h-full;
}

.introduce-3 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 124.2105263158%;
  @apply h-full;
}

.introduce-3 .img a img, .introduce-3 .img a iframe, .introduce-3 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.introduce-3 .icon a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  @apply bg-primary rounded-full;
}

.introduce-3 .icon a img, .introduce-3 .icon a iframe, .introduce-3 .icon a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: contain;
  transition: 0.3s ease-in-out all;
}

.introduce-3 .icon a img {
  padding: 0.2604166667rem;
}

@media (min-width: 576px) {
  .introduce-3 .icon a img {
    padding: 0.9375rem;
  }
}
.introduce-3 .col-right {
  padding-top: 2.0833333333rem;
  padding-bottom: 2.6041666667rem;
}

@media (min-width: 1024px) {
  .introduce-3 .col-right {
    padding-top: 2.8645833333rem;
    padding-bottom: 1.0416666667rem;
  }
}
@media (min-width: 1024px) {
  .introduce-4.section {
    padding-top: 2.34375rem;
    padding-bottom: 2.8645833333rem;
  }
}
.introduce-4 .icon {
  width: 6.7708333333rem;
}

.introduce-4 .icon a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
  @apply rounded-full;
}

.introduce-4 .icon a img, .introduce-4 .icon a iframe, .introduce-4 .icon a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.introduce-4 .icon a span {
  font-size: 2.5rem;
  @apply font-bold uppercase;
  @apply flex items-center justify-center;
}

.introduce-5 nav ul {
  gap: 2.34375rem;
  @apply list-none overflow-auto pb-5px;
}

.introduce-5 nav ul li {
  @apply font-medium text-2xl leading-30px text-text5 whitespace-nowrap;
}

.introduce-5 nav ul li a {
  padding: 0.5208333333rem 0;
  border-bottom: thin solid;
  @apply block w-fit border-transparent transition-all;
}

.introduce-5 nav ul li:hover a, .introduce-5 nav ul li.active a {
  @apply border-primary text-primary;
}

.introduce-5 .item {
  @apply text-xl text-text3;
}

.introduce-5 .item .title {
  @apply font-normal;
}

.introduce-5 .item .title span {
  @apply font-bold text-primary;
}

.introduce-5 .item .des {
  @apply font-bold mt-10px;
}

@media (max-width: 575.98px) {
  .introduce-5 .item .des {
    @apply text-sm;
  }
}
.introduce-5 .list {
  margin-top: 2.0833333333rem;
  column-gap: 0.5208333333rem;
  row-gap: 1.5625rem;
}

@media (min-width: 1024px) {
  .introduce-5 .list {
    gap: 4.6875rem;
  }
}
@media (min-width: 1280px) {
  .introduce-5 .list {
    margin-top: 2.8645833333rem;
  }
}
.introduce-5 .list .item {
  flex: 0 0 100%;
}

@media (min-width: 576px) {
  .introduce-5 .list .item {
    flex: 0 0 calc(33.3333%-10/1920*100rem);
  }
}
@media (min-width: 1024px) {
  .introduce-5 .list .item {
    flex: 0 0 calc(300/1410*100%);
  }
}
@media (max-width: 575.98px) {
  .introduce-5 .list .item {
    @apply flex;
  }

  .introduce-5 .list .item .img {
    flex: 0 0 40%;
  }

  .introduce-5 .list .item .content {
    padding-left: 0.78125rem;
    @apply justify-center flex-1;
  }
}
.introduce-5 .list .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 100%;
}

.introduce-5 .list .img a img, .introduce-5 .list .img a iframe, .introduce-5 .list .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.news-detail .article-header {
  border-bottom: thin solid #E0E0E0;
  @apply pb-10px mb-25px;
}

.news-detail .article-content {
  @apply text-base font-normal leading-24px text-justify text-text3;
}

.news-detail .article-content a[href] {
  @apply text-blue-500;
}

.news-detail .article-content strong {
  @apply font-semibold;
}

.news-detail .article-content * + * {
  @apply mt-5;
}

.news-detail .related-news .img {
  flex: 0 0 calc( 150/450*100%);
}

.news-detail .related-news .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.6666666667%;
}

.news-detail .related-news .img a img, .news-detail .related-news .img a iframe, .news-detail .related-news .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.news-detail .related-news .title {
  @apply font-bold text-lg leading-23px mb-5px;
}

.news-detail .related-news .item + .item {
  @apply mt-5;
}

.news-detail .related-news .content {
  @apply ml-15px;
}

.news-detail .related-news .infos .cat ~ .date::before {
  content: "|";
  @apply mx-10px;
}

.news-detail .share a {
  width: 1.0416666667rem;
  height: 1.0416666667rem;
}

.news-detail .share a svg {
  @apply w-full h-full object-contain;
}

@media (min-width: 1024px) {
  .news-list .bn-3:nth-of-type(2) .content .title {
    @apply flex-none;
  }

  .news-list .bn-3:nth-of-type(2) .content .ctn {
    @apply flex-1;
    @apply line-clamp-6;
  }
}
@media (min-width: 1280px) {
  .linhvuc-detail-1.section {
    padding-bottom: 3.6458333333rem;
  }
}
.linhvuc-detail-1 .des {
  @apply font-light text-lg leading-24px text-text3;
}

@media (min-width: 1024px) {
  .linhvuc-detail-1 .des {
    max-width: 34.1666666667rem;
  }
}
.linhvuc-detail-1 .des * + * {
  @apply mt-25px;
}

.linhvuc-detail-1 .swiper-slide .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 58.2608695652%;
}

.linhvuc-detail-1 .swiper-slide .img a img, .linhvuc-detail-1 .swiper-slide .img a iframe, .linhvuc-detail-1 .swiper-slide .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.linhvuc-detail-1 .thumb {
  margin-top: 10px;
}

@media (min-width: 1280px) {
  .linhvuc-detail-1 .thumb {
    margin-top: 1.0416666667rem;
    padding: 0 3.125rem;
  }
}
.linhvuc-detail-1 .thumb .swiper-slide {
  border: 2px solid;
  @apply border-transparent;
}

@media (min-width: 1024px) {
  .linhvuc-detail-1 .thumb .swiper-slide {
    border: 0.1041666667rem solid;
    @apply border-transparent;
  }
}
.linhvuc-detail-1 .thumb .swiper-slide[class*=thumb-active] {
  @apply border-primary;
}

.linhvuc-detail-2 .item:hover .title {
  @apply text-secondary;
}

.linhvuc-detail-2 .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.4444444444%;
}

.linhvuc-detail-2 .img a img, .linhvuc-detail-2 .img a iframe, .linhvuc-detail-2 .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.linhvuc-list .block-title {
  margin-bottom: 2.0833333333rem;
}

@media (min-width: 1280px) {
  .linhvuc-list .block-title {
    margin-bottom: 3.90625rem;
  }
}
.linhvuc-list .list .item:hover .img::before {
  top: -0.78125rem;
}

.linhvuc-list .list .item:hover:nth-of-type(odd) .img::before {
  right: -0.78125rem;
}

.linhvuc-list .list .item:hover:nth-of-type(even) .img::before {
  left: -0.78125rem;
}

@media (min-width: 1280px) {
  .linhvuc-list .list .item.section {
    padding-top: 5.2083333333rem;
    padding-bottom: 4.1666666667rem;
  }
}
@media (min-width: 1280px) {
  .linhvuc-list .list .item:first-child.section {
    padding-top: 3.6458333333rem;
  }
}
.linhvuc-list .list .item:nth-of-type(odd) {
  @apply bg-secondary;
}

@media (min-width: 1024px) {
  .linhvuc-list .list .item:nth-of-type(odd) .wrapper {
    max-width: 31.3020833333rem;
  }
}
.linhvuc-list .list .item:nth-of-type(odd) .title {
  @apply text-white;
}

.linhvuc-list .list .item:nth-of-type(odd) .des {
  @apply text-white;
}

.linhvuc-list .list .item:nth-of-type(odd) .img {
  @apply mr-auto;
}

@media (min-width: 1024px) {
  .linhvuc-list .list .item:nth-of-type(odd) .img::before {
    right: -1.5625rem;
    @apply bg-primary;
  }
}
@media (min-width: 1024px) {
  .linhvuc-list .list .item:nth-of-type(even) .wrapper {
    max-width: 31.3020833333rem;
  }
}
.linhvuc-list .list .item:nth-of-type(even) .img {
  @apply mr-auto;
}

@media (min-width: 1024px) {
  .linhvuc-list .list .item:nth-of-type(even) .img {
    @apply ml-auto;
  }

  .linhvuc-list .list .item:nth-of-type(even) .img::before {
    left: -1.5625rem;
    @apply bg-secondary;
  }

  .linhvuc-list .list .item:nth-of-type(even) .wrapper {
    max-width: 33.3333333333rem;
    @apply ml-auto;
  }

  .linhvuc-list .list .item:nth-of-type(even) .col-left {
    @apply order-2;
  }

  .linhvuc-list .list .item:nth-of-type(even) .col-right {
    @apply order-1;
  }

  .linhvuc-list .list .item:nth-of-type(even) .title {
    @apply text-primary;
  }

  .linhvuc-list .list .item:nth-of-type(even) .des {
    @apply text-text3;
  }
}
.linhvuc-list .list .img {
  @apply w-full;
}

@media (min-width: 1024px) {
  .linhvuc-list .list .img {
    max-width: calc(100%-15/1920*100rem);
  }
}
.linhvuc-list .list .img::before {
  content: "";
  top: -1.5625rem;
  @apply absolute w-full h-full transition-all duration-500;
}

.linhvuc-list .list .img a {
  position: relative;
  display: block;
  height: 0;
  overflow: hidden;
  padding-top: 56.231884058%;
}

.linhvuc-list .list .img a img, .linhvuc-list .list .img a iframe, .linhvuc-list .list .img a video {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  object-fit: cover;
  transition: 0.3s ease-in-out all;
}

.linhvuc-list .list .title {
  @apply font-light text-32px;
}

.linhvuc-list .list .des {
  @apply font-light text-lg leading-24px text-justify;
}