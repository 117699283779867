.home-4
	.swiper-slide
		&:nth-of-type(even)
			@apply mt-10

nav
	&.common-nav
		ul
			@apply list-none p-0
			@apply overflow-auto
		li
			&:hover,&.active
				@apply text-primary
			a
				@apply whitespace-nowrap
