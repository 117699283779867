.bn
	&.bn-1
		@extend .zoom-img
		.img
			a
				+img-ratio(380/675)
		.title
			@apply transition-all
			@apply line-clamp-2
			@apply font-medium text-xl
			@media (min-width: 768px)
				@apply text-36px leading-47px
		.ctn
			@apply font-normal text-base leading-21px
			@apply transition-all
			@apply line-clamp-3
		.infos
			@apply flex items-center
			@apply font-normal text-sm leading-18px text-text9
			.cat
				~ .date
					&::before
						content: "|"
						@apply mx-10px
		.content
			@apply grid gap-15px md:gap-25px mt-5
		&:hover
			.title
				@apply text-primary
	&.bn-3
		@extend .zoom-img
		.img
			a
				+img-ratio(380/675)
		.title
			@apply transition-all
			@apply line-clamp-2
			@apply font-medium text-xl
			@media (min-width: 768px)
				@apply text-36px leading-47px
		.ctn
			@apply font-normal text-base leading-21px
			@apply transition-all
			@apply line-clamp-3
		.infos
			@apply flex items-center
			@apply font-normal text-sm leading-18px text-text9
			.cat
				~ .date
					&::before
						content: "|"
						@apply mx-10px
		.content
			@apply grid gap-15px md:gap-25px mt-5
		&:hover
			.title
				@apply text-primary
	&.bn-2
		@extend .zoom-img
		@apply relative h-full
		.img
			@apply h-full
			a
				+img-ratio(1/1.2)
				@apply h-full
				@media (min-width: 576px)
					+img-ratio(523/930)
				&::before
					content: ""
					background: linear-gradient(180deg, rgba(82, 7, 4, 0) 41.98%, #490805 93.54%)
					@apply absolute top-0 left-0 w-full h-full z-3
		.content
			@apply text-white absolute bottom-0 w-full z-5 pointer-events-none
			@apply p-10px sm:p-5 xl:p-30px
		.title
			@apply font-semibold text-2xl leading-30px
		.ctn
			@apply font-normal text-base leading-21px mt-10px
			@apply line-clamp-2
		.infos
			@apply flex items-center mt-10px
			@apply font-normal text-sm leading-18px
			.cat
				~ .date
					&::before
						content: "|"
						@apply mx-10px

	&.bn-3
		@extend .zoom-img
		@apply flex h-full flex-col
		.img
			a
				+img-ratio(380/675)
		.title
			@apply transition-all
			@apply line-clamp-2
			@apply font-medium text-xl
			@apply flex-1
			@media (min-width: 768px)
				@apply text-2xl leading-32px
		.ctn
			@apply font-normal text-base leading-21px
			@apply transition-all
			@apply line-clamp-4
		.infos
			@apply flex items-center
			@apply font-normal text-sm leading-18px text-text9
			.cat
				~ .date
					&::before
						content: "|"
						@apply mx-10px
		.content
			@apply grid gap-15px mt-10px sm:mt-5
			@apply flex-1 flex flex-col
		&:hover
			.title
				@apply text-primary
