@tailwind base
@tailwind components
@tailwind utilities

@layer components
	*,
	*::before,
	*::after
		box-sizing: border-box

	*::-webkit-scrollbar-track
		border-radius: r(20px)
		@apply bg-d9d9d9

	*::-webkit-scrollbar
		// width: r(10px)
		width: 0
		height: r(5px)

	*::-webkit-scrollbar-thumb
		border-radius: r(20px)
		@apply bg-primary

	html
		-webkit-text-size-adjust: 100%
		-webkit-tap-highlight-color: rgba(0, 0, 0, 0)

	body
		line-height: 1.5
		font-weight: 400
		@apply font-body text-text3

	img
		display: inline
	.absolute-center
		@apply top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 absolute
	main
		margin-top: var(--fixed-header-height)
