.custom-fancybox-popup
	&.fancybox__container
		.fancybox__carousel
			.fancybox__slide
				.fancybox__content
					@apply p-0 bg-transparent
					.carousel__button
						width: r(50px)
						height: r(50px)
						border-radius: 0
						border-top-right-radius: 10px
						@apply top-0 right-15px outline-none bg-primary
						@media (min-width: 576px)
							border-top-right-radius: r(20px)
						i
							@apply font-normal text-36px
	.wrapper
		padding: 0 0 50px
		border-radius: 10px
		@apply bg-white
		@media (min-width: 576px)
			border-radius: r(20px)
		@media (min-width: 1024px)
			padding: r(45px) r(30px) r(60px)
	.img
		a
			+img-ratio(337/599)
			img
				border-radius: 10px
				@media (min-width: 576px)
					border-radius: r(20px) r(20px) 0 0
				@media (min-width: 1024px)
					border-radius: r(20px)

	.title
		@apply text-2xl sm:text-32px uppercase text-primary font-light
		@media (min-width: 1024px)
			@apply pr-50px
	.ctn
		// max-height: 30vw
		@apply font-light text-lg leading-27px text-text3 overflow-auto mt-5px pr-5px
		@media (min-width: 1024px)
			max-height: r(216px)
		&::-webkit-scrollbar
			width: r(5px)
	.content
		@apply lg:pl-30px px-5 lg:p-0
	.link
		@apply flex items-center text-lg leading-24px text-primary
		i
			@apply text-xl ml-30px
	.col-left
		@media (min-width: 1280px)
			@apply -mr-30px
	.col-right
		@media (min-width: 1280px)
			@apply pl-30px
