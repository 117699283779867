.introduce-5
	nav
		ul
			gap: r(45px)
			@apply list-none overflow-auto pb-5px
			li
				@apply font-medium text-2xl leading-30px text-text5 whitespace-nowrap
				a
					padding: r(10px) 0
					border-bottom: thin solid
					@apply block w-fit border-transparent transition-all
				&:hover,&.active
					a
						@apply border-primary text-primary
	.item
		@apply text-xl text-text3
		.title
			@apply font-normal
			span
				@apply font-bold text-primary
		.des
			@apply font-bold mt-10px
			@media (max-width: 575.98px)
				@apply text-sm
	.list
		margin-top: r(40px)
		column-gap: r(10px)
		row-gap: r(30px)
		@media (min-width: 1024px)
			gap: r(90px)
		@media (min-width: 1280px)
			margin-top: r(55px)
		.item
			flex: 0 0 100%
			@media (min-width: 576px)
				flex: 0 0 calc(33.3333%-10/1920*100rem)
			@media (min-width: 1024px)
				flex: 0 0 calc(300/1410*100%)
			@media (max-width: 575.98px)
				@apply flex
				.img
					flex: 0 0 40%
				.content
					padding-left: r(15px)
					@apply justify-center flex-1
		.img
			a
				+img-ratio(1/1)
