.introduce-3
	.img
		@apply h-full
		a
			+img-ratio(708/570)
			@apply h-full
	.icon
		a
			+img-ratio(1/1,contain)
			@apply bg-primary rounded-full
			img
				padding: r(5px)
				@media (min-width: 576px)
					padding: r(18px)
	.col-right
		padding-top: r(40px)
		padding-bottom: r(50px)
		@media (min-width: 1024px)
			padding-top: r(55px)
			padding-bottom: r(20px)
