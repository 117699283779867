.home-2
	.des
		max-width: r(1167px)
	.img
		a
			+img-ratio(1.7/2)
			@media (min-width: 576px)
				+img-ratio(840/455)
			&::before
				content: ""
				background: rgba(0, 0, 0, 0.3)
				@apply w-full left-0 top-0 h-full z-2 absolute transition-all duration-300
	.flow-title
		writing-mode: vertical-lr
		white-space: nowrap
		transform: scale(-1)
		bottom: 10px
		left: 10px
		@apply text-36px font-bold
		@media (min-width: 1280px)
			bottom: r(60px)
			left: r(50px)
	.title,.ctn
		@apply transition-all duration-300
	.content
		bottom: 0
		padding: 10px
		@apply font-normal text-base leading-21px overflow-hidden
		@media (min-width: 1280px)
			padding: r(50px) r(60px) r(55px)
		.title,.ctn
			@apply opacity-0 translate-x-10
	.title
		@apply text-36px
	.item
		@media (min-width: 576px)
			&:hover
				.flow-title
					@apply -translate-x-full -scale-100 opacity-0
				.content
					.title,.ctn
						@apply translate-x-0 opacity-100 delay-150 duration-500
					.ctn
						@apply delay-300
				.img
					a
						&::before
							background: rgba(0, 0, 0, 0.5)
		@media (max-width: 575.98px)
			.flow-title
				@apply -translate-x-full -scale-100 opacity-0
			.content
				.title,.ctn
					@apply translate-x-0 opacity-100 delay-150 duration-500
				.ctn
					@apply delay-300
			.img
				a
					&::before
						background: rgba(0, 0, 0, 0.5)
