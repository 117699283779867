.contact
	.des
		@apply font-normal text-lg leading-23px text-text3
	.form-group
		textarea,input:not([type='submit'])
			padding: r(13.5px) r(14px)
			border: thin solid #f0f0f0
			@apply font-normal text-lg leading-23px
			@apply w-full
		textarea
			height: r(150px)
			@apply resize-none
	.submit-form
		@apply grid gap-10px
		@media (max-width: 575.98px)
			@apply mt-10px
		input
			width: r(210px)
			height: r(46px)
			@apply bg-primary rounded-full
			@apply font-normal text-xl leading-5 text-white
	.form-submit
		@apply flex justify-between
		@media (max-width: 575.98px)
			@apply flex-col
	.contact-form
		form
			@apply grid gap-5
	.iframe
		height: r(421px)
		@apply w-full
		iframe
			@apply w-full h-full
	.title
		font-size: r(20px)
		@apply font-semibold text-primary
		@media (min-width: 576px)
			font-size: r(26px)
			line-height: r(31px)
	.infos
		@apply font-normal text-base sm:text-lg leading-30px mt-15px mb-5
		.icon
			flex: 0 0 r(20px)
			i
				@apply text-lg leading-30px text-text3
		.ctn
			@apply ml-10px sm:ml-5
