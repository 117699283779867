.global-breadcrumb
	border-bottom: thin solid #EDEDED
	@apply bg-white
	nav.rank-math-breadcrumb
		padding: r(13.5px) 0
		@apply text-base font-normal leading-18px text-text6
		span.separator
			margin: 0 5px
			font-size: 0
			@media (min-width: 768px)
				margin: 0 r(30px)
			&::before
				content: "|"
				@apply text-base
	a
		&:hover
			@apply text-primary
.single
	.global-breadcrumb
		a
			&:nth-of-type(3)
				@apply hidden
		span
			&:nth-of-type(2),&:nth-of-type(3),&:nth-of-type(4)
				@apply hidden
