.linhvuc-detail-1
	&.section
		@media (min-width: 1280px)
			padding-bottom: r(70px)
	.des
		@apply font-light text-lg leading-24px text-text3
		@media (min-width: 1024px)
			max-width: r(656px)
		* + *
			@apply mt-25px
	.swiper-slide
		.img
			a
				+img-ratio(402/690)

	.thumb
		margin-top: 10px
		@media (min-width: 1280px)
			margin-top: r(20px)
			padding: 0 r(60px)
		.swiper-slide
			border: 2px solid
			@apply border-transparent
			@media (min-width: 1024px)
				border: r(2px) solid
				@apply border-transparent
			&[class*='thumb-active']
				@apply border-primary

.linhvuc-detail-2
	.item
		@extend .zoom-img
		&:hover
			.title
				@apply text-secondary
	.img

		a
			+img-ratio(254/450)
