.introduce-2
	@media (min-width: 1280px)
		padding-top: r(50px)
	.item
		@apply h-full flex flex-col
		.content
			@apply font-light text-base leading-21px text-text3
			@apply flex-1
		.line
			width: r(2px)
			left: r(5px)
			@apply h-full bg-primary absolute
			&::before,&::after
				content: ''
				width: r(10px)
				height: r(10px)
				@apply absolute bg-primary rounded-full -translate-x-1/2 left-1/2
			&::before
				@apply top-0
			&::after
				@apply bottom-0
		.ctn
			max-height: r(147px)
			@apply pl-5 overflow-auto
			&::-webkit-scrollbar
				width: r(3px)
				height: r(3px)
		.year
			clip-path: polygon(0% 0%, 94% 0%, 100% 50%, 94% 100%, 0% 100%)
			padding: r(4px) r(25px)
			@apply bg-primary
			@apply font-semibold text-2xl text-white mt-35px
	.swiper-slide
		@apply h-auto
