.linhvuc-list
	.block-title
		margin-bottom: r(40px)
		@media (min-width: 1280px)
			margin-bottom: r(75px)
	.list
		.item
			&:hover
				.img
					&::before
						top: r(-15px)
				&:nth-of-type(odd)
					.img
						&::before
							right: r(-15px)
				&:nth-of-type(even)
					.img
						&::before
							left: r(-15px)

			&.section
				@media (min-width: 1280px)
					padding-top: r(100px)
					padding-bottom: r(80px)
			&:first-child
				&.section
					@media (min-width: 1280px)
						padding-top: r(70px)
			&:nth-of-type(odd)
				@apply bg-secondary
				.wrapper
					@media (min-width: 1024px)
						max-width: r(601px)
				.title
					@apply text-white
				.des
					@apply text-white
				.img
					@apply mr-auto
					@media (min-width: 1024px)
						&::before
							right: r(-30px)
							@apply bg-primary
			&:nth-of-type(even)
				// @apply bg-secondary
				.wrapper
					@media (min-width: 1024px)
						max-width: r(601px)
				.img
					@apply mr-auto
			@media (min-width: 1024px)
				&:nth-of-type(even)
					.img
						@apply ml-auto
						&::before
							left: r(-30px)
							@apply bg-secondary
					.wrapper
						max-width: r(640px)
						@apply ml-auto
					.col-left
						@apply order-2
					.col-right
						@apply order-1
					.title
						@apply text-primary
					.des
						@apply text-text3
		.img
			@apply w-full
			@media (min-width: 1024px)
				max-width: calc(100%-15/1920*100rem)
			&::before
				content: ""
				top: r(-30px)
				@apply absolute w-full h-full transition-all duration-500
			a
				+img-ratio(388/690)

		.title
			@apply font-light text-32px
		.des
			@apply font-light text-lg leading-24px text-justify
